import { useState, useEffect } from 'react';

import { FaCalendarAlt, FaEye, FaExternalLinkAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { prependBaseUrl } from '../utils/helpers';


const MySwal = withReactContent(Swal);

const PortFolioItem = ({ title, description, date, url, preview = true, image, labels, items, dates, rows = false, language }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleShowWebsite = () => {
        MySwal.fire({
            html: `<div style="padding: 0; margin: 0;"><iframe src="${url}" width="100%" height="800" style="padding:0;border:none;"></iframe></div>`,
            showCloseButton: true,
            showConfirmButton: false,
            width: isMobile ? '96%' : '80%',
            height: '100%',
            padding: '0',
            background: '#fff',
            customClass: {
                popup: 'custom-swal-popup-noborders rounded-lg shadow-lg',
                closeButton: 'bg-green-500 hover:bg-green-700 hover:text-white text-white',
            },
        });
    };

    return (
        <div tabIndex="0" className="portfolio-item w-full text-white shadow-lg p-4 rounded-lg border-t-4 border-green-500 bg-green-900  focus:ring-2 focus:ring-green-500">
            <div className={"grid grid-cols-1 gap-4" + (image && ' md:grid-cols-2')}>
                <div className="flex flex-col flex-grow">
                    <div className='flex flex-row items-center gap-2 justify-between'>
                        <h3 className="text-xl font-bold">{title}</h3>
                        {date && <span className="text-white"> {date} </span>}
                    </div>
                    {description && <p className="mt-2 whitespace-pre-line flex-grow">{description}</p>}
                    {items && (
                        <div className={`mt-4 ${!rows ? 'flex flex-row flex-wrap gap-2' : 'flex flex-col gap-2'}`}>
                            {items && items.map((item, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                                    <p className="flex-grow md:whitespace-nowrap">{item.title}</p>
                                    <p className="">{item.description}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {(image || dates) && <div>
                    {image && <img src={prependBaseUrl(image)} className="w-full rounded" />}
                    {dates && <div className="mt-4 flex flex-col gap-6">
                        {Object.entries(dates).map((item, index) => (
                            <div key={index} className="flex flex-col items-center gap-2">
                                <div className='flex flex-row items-center gap-2'>
                                    <FaCalendarAlt className="text-gray-500" />
                                    <p>{item[0]}</p>
                                </div>
                                <p>{item[1]}</p>
                            </div>
                        ))}
                    </div>}
                </div>}
            </div>
            {url && preview && <button
                className="mt-4 bg-green-500 text-white py-2 px-4 rounded w-full hover:bg-green-700 flex items-center justify-center"
                onClick={handleShowWebsite} >
                <FaEye className="mr-2" /> Preview Website
            </button>}
            {url && <a href={url} target="_blank" rel="noreferrer" className="mt-4 px-4 rounded block w-full text-center text-white hover:text-blue-300 font-bold flex items-center justify-center">
                <FaExternalLinkAlt className="mr-2" /> Visit Website
            </a>}
        </div>
    );
}

export default PortFolioItem;
