import { useScroll, motion, useTransform } from 'framer-motion';
import React from 'react';

const ParallaxSection = ({ src, children }) => {
    const { scrollY } = useScroll();
    const y = useTransform(scrollY, [0, 1000], [0, -200]);

    return (
        <motion.div
            style={{ ...y, backgroundImage: `url(${src})` }}
            className={`full-width-section w-screen h-40 bg-cover bg-center`}
        >
            <div className="h-full flex items-center justify-center">
                {children}
            </div>
        </motion.div>
    );
};

export default ParallaxSection;