import { prependBaseUrl } from '../utils/helpers';

const AboutSection = ({ content }) => {
    if (!content.portfolio) return null;

    return <section className="my-8 w-full flex flex-col sm:flex-row gap-4" id="about">
        <div className="md:col-span-2 flex flex-col flex-1 gap-4 ">
            <h2 className="text-3xl text-white font-bold">{content.portfolio.about_title}</h2>
            <p className="mt-4 text-lg text-white whitespace-pre-line">{content.portfolio.about_description}</p>
            {content.portfolio.cta_title && <a href={content.portfolio.cta_link ?? '#contact'} className="bg-green-400 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-green-700 mt-4">{content.portfolio.cta_title}</a>}
        </div>
        {content.portfolio.hero?.url && <div className="md:col-span-1 flex flex-col flex-1 items-center gap-4">
            <img src={prependBaseUrl(content.portfolio.hero.url)} width={300} />
        </div>}
    </section>;
};

export default AboutSection;