import React from 'react';
import { PortFolioItem } from './';
import { sortByDate } from '../utils/helpers';

const PortFolioDisplay = ({ content, language }) => {
    return (
        <>
            {content.items && content.items.map((item, index) => {
                if (!item.items || item.items.length < 1) return null;
                const sortedItems = sortByDate(item.items);

                return <div key={index}>
                    <h2 className="text-3xl text-white font-bold mt-8" id={item.id}>{item.title}</h2>
                    <p className='mt-4 text-white'>{item.description}</p>
                    <br />
                    <div className='grid gap-4'>
                        {sortedItems.map((item, index) => (
                            <div key={index}>
                                <PortFolioItem key={index} title={item.title} description={item.description} date={item.date} dates={item.dates} rows={item.rows} url={item.url} image={item.image?.url} language={language} items={item.items} />
                                <div className="h-8" />
                            </div>
                        ))}
                    </div>
                </div>
            })}
        </>
    );
}

export default PortFolioDisplay;