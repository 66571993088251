import { useEffect } from 'react';

const FooterSection = ({ id, content }) => {
    useEffect(() => {
        const handleTitleClick = () => {
            const rupsKeys = ['r', 'u', 'p', 's'];
            rupsKeys.forEach((key, index) => {
                setTimeout(() => {
                    window.dispatchEvent(new KeyboardEvent('keydown', { key }));
                }, index * 100);
            });
        };

        const titleElement = document.getElementById(id).querySelector('p');
        titleElement.addEventListener('click', handleTitleClick);

        return () => {
            titleElement.removeEventListener('click', handleTitleClick);
        };
    }, [id]);

    return (
        <footer id={id} className="full-width-section flex flex-col md:flex-row justify-center gap-8 px-4 py-4 items-center bg-green-500 text-white">
            <p>&copy; {content.portfolio.about_title !== 'Ruben Cruijsen' && '2019 - '}{new Date().getFullYear().toString()} {content.portfolio.about_title}</p>
            {content.portfolio.contact_kvk && <p>KvK: {content.portfolio.contact_kvk}</p>}
            {content.portfolio.contact_btw && <p>BTW: {content.portfolio.contact_btw}</p>}
        </footer>
    );
};

export default FooterSection;
