import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { RupsLogo } from './';

const RupsBar = ({ type }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    let animate = {};
    let transition = {};
    let types = {
        fast: {
            animate: { x: [-100, 3000] },
            transition: { duration: 3, repeat: 0, repeatType: "loop" }
        }, slow: {
            animate: { x: [-300, 3000] },
            transition: { duration: 100, repeat: Infinity, repeatType: "loop" }
        }, random: {
            animate: { x: [-300, 10, 200, 10, -11010, -129, 291, -209] },
            transition: { duration: 9, repeat: Infinity, repeatType: "loop" }
        }, peaking: {
            animate: { x: [-300, 10, 10, -300] },
            transition: { duration: 9 }
        }
    };
    if (!type) {
        type = Object.keys(types)[Math.floor(Math.random() * Object.keys(types).length)];
    }
    animate = types[type].animate;
    transition = types[type].transition;

    return (
        <section ref={ref} className="full-width-section my-8 w-screen relative overflow-hidden h-24">
            <motion.div
                className="absolute top-0 left-0 w-32"
                initial="hidden"
                animate={controls}
                variants={{
                    hidden: { x: -300 },
                    visible: animate
                }}
                transition={transition}
            >
                <RupsLogo />
            </motion.div>
        </section>
    );
};

export default RupsBar;