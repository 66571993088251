import React, { useEffect, useState } from 'react';
import { Header, AboutSection, PortFolioDisplay, RupsBar, ParallaxSection, ContactSection, FooterSection, LoadingScreen, RupsLogoController } from './components';
import { fetchContent } from './utils/contentFetcher';

function App() {
  const [language, setLanguage] = useState('nl');
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getContent = async () => {
      if(!loaded) setLoading(true);
      const data = await fetchContent(language);
      setContent(data);
      setLoading(false);
      setLoaded(true);
    };
    getContent();
  }, [language]);

  useEffect(() => {
    const path = window.location.pathname;
    const search = window.location.search;
    if (path.startsWith('/en/')) {
      setLanguage('en');
    }
  }, []);

  useEffect(() => {
    const search = window.location.search;
    if (language === 'en') {
      window.history.pushState(null, '', `/en/${search}`);
    } else {
      window.history.pushState(null, '', `/${search}`);
    }
  }, [language]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-green-800 overflow-x-hidden">
      <main className="flex-grow flex flex-col justify-center px-4 sm:px-6 lg:px-8 max-w-screen-lg lg:mx-auto w-full">
        <RupsLogoController />
        <AboutSection content={content} language={language} />
        <RupsBar />
        <Header content={content} language={language} setLanguage={setLanguage} />
        <ParallaxSection src='/assets/images/coffee.jpg' height="40" />
        <PortFolioDisplay content={content} language={language} />
        <ParallaxSection src='/assets/images/notebook.jpg' height="40" />
        <ContactSection id="contact" content={content} language={language} />
        <FooterSection id="footer" content={content} language={language} />
      </main>
    </div>
  );
}

export default App;