export const prependBaseUrl = (url) => {
  const baseUrl = "https://api.rups.cloud";
  return `${baseUrl}${url}`;
};

export const extractYear = (dateString) => {
  if(!dateString) return null;
  const match = dateString.match(/\b\d{4}\b/);
  return match ? parseInt(match[0], 10) : null;
};

export const sortByDate = (items) => {
  return items.sort((a, b) => {
    if (a.position !== undefined && b.position !== undefined) {
      return a.position - b.position;
    }
    const yearA = extractYear(a.date);
    const yearB = extractYear(b.date);
    if (yearA && yearB) {
      return yearB - yearA;
    } else if (yearA) {
      return -1;
    } else if (yearB) {
      return 1;
    } else {
      return 0;
    }
  });
};