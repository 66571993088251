import { FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';

const ContactSection = ({ id, content }) => {
    return (
        <section className="py-16" id={id}>
            <div className="flex flex-col lg:flex-row lg:justify-between">
                <div className="flex flex-col lg:items-start">
                    <h2 className="text-3xl font-bold text-white">{content.portfolio.about_title}</h2>
                    <p className="mt-4 text-white">{content.portfolio.contact_description}</p>
                    {content.portfolio.contact_email && <a href={`mailto:${content.portfolio.contact_email}`} className="mt-4 bg-green-500 hover:bg-green-700 w-full text-white py-2 px-4 rounded flex items-center">
                        <FaEnvelope className="mr-2" /> {content.portfolio.contact_email}
                    </a>}
                    {content.portfolio.contact_phone && (
                        <a href={`tel:+31612345678${content.portfolio.contact_phone}`} className="mt-2 bg-green-500 hover:bg-green-700 w-full text-white py-2 px-4 rounded flex items-center">
                            <FaPhone className="mr-2" /> {content.portfolio.contact_phone}
                        </a>
                    )}
                    {content.portfolio.contact_facebook && (
                        <a href={content.portfolio.contact_facebook} className="mt-2 bg-green-500 hover:bg-green-700 w-full text-white py-2 px-4 rounded flex items-center">
                            <FaFacebook className="mr-2" /> Facebook
                        </a>
                    )}
                    {content.portfolio.contact_instagram && (
                        <a href={content.portfolio.contact_instagram} className="mt-2 bg-pink-500 hover:bg-pink-700 text-white py-2 px-4 rounded flex items-center">
                            <FaInstagram className="mr-2" /> Instagram
                        </a>
                    )}
                </div>
                {content.portfolio.enable_contact_form && (
                    <div className="mt-8 lg:mt-0 items-center lg:items-start lg:ml-8 lg:w-1/2">
                        <h3 className="text-2xl font-bold">{content.portfolio.contact_cta_title}</h3>
                        <form className='mt-4'>
                            <input type="text" placeholder="Your Name" className="border p-2 mb-2 w-full" />
                            <input type="email" placeholder="Your Email" className="border p-2 mb-2 w-full" />
                            <textarea placeholder="Your Message" className="border p-2 mb-2 w-full h-36"></textarea>
                            <button type="submit" className="bg-green-500 hover:bg-green-700 w-full text-white py-2 px-4 rounded">Send</button>
                        </form>
                    </div>
                )}
            </div>
        </section>
    );
}
export default ContactSection;