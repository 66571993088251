import React, { useEffect, useState } from 'react';
import RupsLogo from './RupsLogo';

const RupsLogoController = () => {
  const [typedKeys, setTypedKeys] = useState('');
  const [showRupsLogo, setShowRupsLogo] = useState(false);
  const [logoPosition, setLogoPosition] = useState({ top: 0, left: 0 });
  const [elements, setElements] = useState([]);
  const [timeouts, setTimeouts] = useState([]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      setTypedKeys((prevKeys) => {
        const newKeys = (prevKeys + event.key).slice(-4);
        if (newKeys === 'rups') {
          setShowRupsLogo(true);
          const allElements = document.querySelectorAll('h1, h2, h3, h4, h5, h6, p, a, button');
          setElements(Array.from(allElements).filter(el => el.offsetParent !== null && el.textContent.trim() !== ''));
        } else if (showRupsLogo) {
          setShowRupsLogo(false);
          setTypedKeys('');
        }
        return newKeys;
      });
    };

    const handleClick = () => {
      setShowRupsLogo(false);
      timeouts.forEach(clearTimeout);
      setTimeouts([]);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClick);
    };
  }, [timeouts]);

  useEffect(() => {
    if (showRupsLogo && elements.length > 0) {
      let index = 0;
      const moveLogo = () => {
        if (!showRupsLogo) return;
        const element = elements[index];
        const rect = element.getBoundingClientRect();
        setLogoPosition({ top: rect.top + window.scrollY - 80, left: rect.left + window.scrollX - 20 });
        const scrollTimeout = setTimeout(() => {
          window.scrollTo({
            top: rect.top + window.scrollY - window.innerHeight / 2,
            left: rect.left + window.scrollX - window.innerWidth / 2,
            behavior: 'smooth'
          });
        }, 1000);
        const delay = element.tagName === 'P' && element.textContent.length > 100 ? 4000 : 3000;
        index = (index + 1) % elements.length;
        const moveTimeout = setTimeout(moveLogo, delay);
        setTimeouts((prevTimeouts) => [...prevTimeouts, scrollTimeout, moveTimeout]);
      };

      moveLogo();
    }
  }, [showRupsLogo, elements]);

  return showRupsLogo ? <RupsLogo position={logoPosition} /> : null;
};

export default RupsLogoController;
