import { useState, useEffect } from 'react';

const Header = ({ content, language, setLanguage}) => {
    const [isPopupMenuOpen, setIsPopupMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [shake, setShake] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let links = [
        { href: "#about", text: content.portfolio.about_title },
    ];
    content.items && content.items.map((item) => links.push({ href: `#${item.id}`, text: item.title }));
    links.push({ href: "#contact", text: 'Contact' });

    useEffect(() => {
        document.getElementsByClassName('menu-button')[0].addEventListener('mouseover', () => { setIsPopupMenuOpen(true); })
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.popup-menu') && !event.target.closest('.menu-button')) {
                setIsPopupMenuOpen(false);
            }
        };

        if (isPopupMenuOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isPopupMenuOpen]);

    useEffect(() => {
        const handleScreenClick = (event) => {
            if (!event.target.closest('header')) {
                setShake(true);
                setTimeout(() => setShake(false), 500);
            }
        };

        window.addEventListener('click', handleScreenClick);
        return () => window.removeEventListener('click', handleScreenClick);
    }, []);
    if(!content.portfolio) return null;
    return (
        <header className={`bg-green-800 shadow w-full flex items-center justify-between px-4 sm:px-6 lg:px-8 sticky top-0 z-50 ${isMobile ? '' : 'border-b-2 border-green-700'}`}>
            <nav className="hidden md:flex items-center gap-4 w-full justify-between">
                {links.map(link => (
                    <a key={link.href} href={link.href} className="text-white hover:text-green-400">
                        {link.text}
                    </a>
                ))}
                <button onClick={() => setLanguage(language === 'en' ? 'nl' : 'en')} className="text-white hover:text-green-400">
                    <span className={language === 'nl' ? 'text-green-400' : ''}>NL</span> / <span className={language === 'en' ? 'text-green-400' : ''}>EN</span>
                </button>
            </nav>

            <button
                style={{ color: '#28a745' }}
                className={`bg-white rounded-full w-12 h-12 shadow-xl menu-button text-gray-800 focus:outline-none fixed bottom-14 md:bottom-8 right-4 xl:right-16 ${shake ? 'shake' : ''}`}
                onClick={() => setIsPopupMenuOpen(!isPopupMenuOpen)}
            >
                &#9776;
            </button>

            {isPopupMenuOpen && (
                <div className="popup-menu fixed bottom-28 md:bottom-24 right-4 xl:right-16 bg-white p-4 rounded shadow-lg flex flex-col items-center gap-4 z-50">
                    {links.map(link => (<a key={link.href} href={link.href} className="text-gray-800 hover:text-green-700">{link.text}</a>))}
                    <button onClick={() => setLanguage(language === 'en' ? 'nl' : 'en')} className="text-gray-800 hover:text-green-700">
                        <span className={language === 'nl' ? 'text-green-700' : ''}>NL</span> / <span className={language === 'en' ? 'text-green-700' : ''}>EN</span>
                    </button>
                </div>
            )}
        </header>
    );
}

export default Header;