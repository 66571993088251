import { RupsLogo } from "./";

const LoadingScreen = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-green-800">
            <RupsLogo />
            <div className="text-3xl text-white">Loading...</div>
        </div>
    );
};
export default LoadingScreen;