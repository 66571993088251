import { fetchWithFilters } from 'rups-components';

export const fetchContent = async (language) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const disableCaching = process.env.REACT_APP_DISABLE_CONTENT_CACHING === 'true' ?? false;
  const cacheExpiryTime = 3600000;

  const locale = language === 'en' ? 'en' : 'nl';
  const hostname = window.location.hostname;
  const urlParams = new URLSearchParams(window.location.search);
  const site = (
    (hostname.includes('rubencruijsen') || urlParams.get('rubencruijsen')) ? 'rubencruijsen.nl' : 'rupswebsites.nl'
  ) + (locale === 'nl' ? '' : '/en/');
  try {
    const cachedData = localStorage.getItem(`content-${site}-${locale}`);
    const cachedTimestamp = parseInt(localStorage.getItem(`content-timestamp-${site}-${locale}`), 10);
    const currentTime = new Date().getTime();
    if (!disableCaching && (cachedData && (cachedTimestamp && (currentTime - cachedTimestamp < cacheExpiryTime)))) {
      return JSON.parse(cachedData);
    }
    const toFetch = [
      'sites',
      'portfolios',
      'services',
      'skills',
      'experiences',
      'projects',
    ];
    const fetchedData = await Promise.all(toFetch.map(path => fetchWithFilters(apiUrl, path, site, locale)));
    const content = toFetch.reduce((acc, key, index) => {
      acc[key] = fetchedData[index];
      return acc;
    }, {});

    const data = {
      'portfolio': content.portfolios.data[0],
      'items': []
    };
    toFetch.forEach((key) => {
      if (data.portfolio[key + "_title"]) {
        data.items.push({
          'id': key,
          'title': data.portfolio[key + "_title"],
          'description': data.portfolio[key + "_description"],
          'items': content[key].data
        });
      }
    });

    document.title = data.portfolio.about_title || 'RupsWebsites.nl / RubenCruijsen.nl';
    document.querySelector('meta[name="description"]').setAttribute('content', data.seo_description || 'Web site created using create-react-app');

    localStorage.setItem(`content-${site}-${locale}`, JSON.stringify(data));
    localStorage.setItem(`content-timestamp-${site}-${locale}`, currentTime.toString());

    return data;
  } catch (error) {
    console.error('Error fetching content:', error);
    return null;
  }
};
