import { motion } from 'framer-motion';
import { useState } from 'react';

const RupsLogo = ({ position }) => {
  const walk_forward = {
    x: [0, 300, 0]
  };
  const walk_backward = {
    x: [0, -300, 0]
  };
  const do_a_flip = {
    rotate: [0, 360]
  };
  const animations = [walk_forward, walk_backward, do_a_flip];

  const [animation, setAnimation] = useState({});

  const handleAnimationComplete = () => {
    // setAnimation({})
  };

  const handleHover = () => {
    // pick a random animation out of the animations array
  }

  const handleClick = () => {
    const animation = animations[Math.floor(Math.random() * animations.length)];
    setAnimation({});
    setAnimation(animation);
  }

  const style = {
    position: position ? 'absolute' : 'relative',
    top: position ? `${position.top}px` : 'auto',
    left: position ? `${position.left}px` : 'auto',
    width: '100px',
    height: '100px',
    background: 'url(/assets/images/rups-idee.png) no-repeat center center',
    backgroundSize: 'contain',
    zIndex: position ? 1000 : 'auto',
  };

  return position ? (
    <motion.div
      style={style}
      animate={animation}
      transition={{ duration: 1, repeat: 0, repeatType: "loop" }}
      onMouseEnter={() => handleHover()}
      onClick={() => handleClick()}
      onAnimationComplete={handleAnimationComplete}
      layout
    />
  ) : (
    <motion.img
      src='/assets/images/rups-idee.png'
      className="w-32"
      alt="logo"
      animate={animation}
      transition={{ duration: 5, repeat: 0, repeatType: "loop" }}
      onMouseEnter={() => handleHover()}
      onClick={() => handleClick()}
      onAnimationComplete={handleAnimationComplete}
    />
  );
};

export default RupsLogo;